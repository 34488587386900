/* eslint-disable camelcase */
import { getLocale } from '@/lang'

export class UserTranslation {
  id: string | null;
  locale: string;
  description: string;
  first_name: string;
  last_name: string;
  address: string;

  constructor(params: any = {}) {
    this.id = params.id || null
    this.locale = params.locale || getLocale()
    this.description = params.description || ''
    this.first_name = params.first_name || ''
    this.last_name = params.last_name || ''
    this.address = params.address || ''
  }
}
