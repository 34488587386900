












































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky/index.vue'
import {
  locales,
  convertToMapTranslations,
  convertToArrayTranslations,
  hasPermission,
  errorMsg,
  successMsg,
  confirmDialog,
  validateForm, hasRoles, getTranslations
} from '@/utils'
import Tinymce from '@/components/Tinymce/index.vue'
import { UserTranslation } from '@/models/UserTranslation'
import { deleteUser, getUser, saveUser, saveUserWithMedia } from '@/api/users'
import { User } from '@/models/User'
import { getRolesLight } from '@/api/roles'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { Form } from 'element-ui'
import {
  getRemoteUsers,
  getUsersLight,
  getOfficesLight
} from '@/api/helpers'
import {
  createFilter,
  FilterOperator,
  FilterType
} from '@/utils/filter'

@Component({
  name: 'UserDetail',
  components: {
    Tinymce,
    Sticky
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  @Prop({ default: false }) private isProfile!: boolean

  private translations = convertToMapTranslations([], UserTranslation)
  private skeletonLoader = false
  private saveLoader = false
  private roles = []
  private languages = locales
  private language = locales[0]
  private usersLoading = false
  private users: any = []
  private offices: any = []
  private imageUrl = ''
  private imageUploaded: any = undefined
  private getTranslations = getTranslations
  private hasPermission = hasPermission
  private owner: any = null
  private user = new User({
    owner: UserModule.id,
    office_id: UserModule.officeId,
    active: true
  });

  private atLeastOneLanguage = (rule: any, value: string, callback: Function) => {
    const isValid = this.languages.some((language) => {
      return this.translations[language][rule.field]
    })
    return isValid ? callback() : callback(this.$t('form.isRequired'))
  }

  private rules = {
    first_name: [{
      validator: this.atLeastOneLanguage,
      trigger: 'blur'
    }],
    last_name: [{
      validator: this.atLeastOneLanguage,
      trigger: 'blur'
    }],
    password: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }],
    phone: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }],
    username: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }],
    main_email: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }, {
      type: 'email',
      message: this.$t('form.isEmail'),
      trigger: 'blur'
    }],
    tax_identification_number: [{
      min: 9,
      max: 9,
      message: this.$t('form.isVat'),
      trigger: 'blur'
    }]
  }

  created() {
    Promise.all([
      getRolesLight({
        query: {
          pagination: {
            fetch_all: true
          }
        }
      }).then(({ data }) => {
        this.roles = data.collection || []
      }).catch(() => {
        this.roles = []
      }),
      this.getItem()
    ]).then(() => {
      if (this.canChangeOwner) {
        Promise.all([
          this.getUsersList([{
            type: FilterType.field,
            key: 'id',
            value: this.user.owner,
            operator: FilterOperator.eq
          }]),
          this.getOfficesList()
        ])
      }
    })
  }

  get title() {
    if (this.isProfilePage) {
      return this.$t('userDetail.profile')
    }
    if (this.isEdit) {
      return this.$t('userDetail.editUser')
    }
    return this.$t('userDetail.newUser')
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  get isProfilePage() {
    return this.isProfile || (
      this.isEdit && this.$route.params.id === UserModule.id
    )
  }

  get canChangeOwner() {
    if (hasRoles(['super_admin'], UserModule.roles)) {
      return true
    }
    return !this.isProfilePage && hasPermission([
      'EDIT_OFFICES',
      'CREATE_OFFICES'
    ])
  }

  private async getItem() {
    if (!this.isProfilePage && !this.isEdit) {
      return Promise.resolve()
    }
    try {
      this.skeletonLoader = true
      const id = this.isProfile ? UserModule.id : this.$route.params.id
      const { data } = await getUser({
        id: id,
        withRoles: true
      })
      this.user = data
      this.translations = Object.assign(
        this.translations,
        convertToMapTranslations(
          data.translations,
          UserTranslation
        )
      )
      if (this.user.photo != null) {
        this.imageUrl = '/static/users/' + this.user.id + '/images/' + this.user.photo
      } else {
        this.imageUrl = '/static/public/placeholder.png'
      }
    } catch (err) {
      await errorMsg('api.entityNotFound')
    } finally {
      this.skeletonLoader = false
    }
  }

  private handleImageSuccess(res: any, file: any) {
    this.imageUploaded = file
    this.imageUrl = URL.createObjectURL(file.raw)
  }

  private async deleteItem() {
    try {
      const [data] = await confirmDialog('userList.delete')
      if (!data) return
      await deleteUser({
        id: this.user.id
      })
      await successMsg('userList.deleteSuccess')
      return this.$router.push('/user/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private onOwnerChange() {
    if (this.owner) {
      this.user.owner = this.owner.id
    } else {
      this.user.owner = UserModule.id
    }
  }

  private async getUsersList(filters: any) {
    try {
      const users = await getUsersLight(createFilter(filters))
      if (users.data.collection && users.data.collection[0]) {
        this.users = users.data.collection
        this.owner = users.data.collection[0]
      }
    } catch (e) {
      this.users = []
      this.owner = null
    }
  }

  private async getOfficesList() {
    try {
      const { data } = await getOfficesLight({
        query: {
          pagination: {
            fetch_all: true
          }
        }
      })
      if (data.collection && data.collection[0]) {
        this.offices = data.collection
      }
    } catch (e) {
      this.offices = []
    }
  }

  private onOfficeChange() {
    this.owner = null
    this.user.owner = UserModule.id
    this.users = []
    this.getUsersList([{
      type: FilterType.field,
      key: 'office_id',
      value: this.user.office_id,
      operator: FilterOperator.eq
    }, {
      type: FilterType.field,
      key: 'roles.name',
      value: 'BROKER',
      operator: FilterOperator.eq
    }])
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/user/edit/' + id)
  }

  private async getRemoteUsersList(query: string) {
    this.usersLoading = true
    this.users = await getRemoteUsers(query, [{
      type: FilterType.field,
      key: 'roles.name',
      value: 'BROKER',
      operator: FilterOperator.eq
    }, {
      type: FilterType.field,
      key: 'office_id',
      value: this.user.office_id,
      operator: FilterOperator.eq
    }])
    this.usersLoading = false
  }

  private beforeSave() {
    this.user.translations = convertToArrayTranslations(this.translations, UserTranslation)
    this.user.office = null
    this.user.owner = this.user.owner || UserModule.id
    this.user.office_id = this.user.office_id || UserModule.officeId
    this.user.roles = this.user.roles.map((role: any) => {
      return { id: role.id }
    })
  }

  private async saveItem() {
    try {
      const [valid] = await validateForm(this.$refs.form as Form)
      if (!valid) return errorMsg('form.formErrors')
      this.saveLoader = true
      this.beforeSave()
      let data: any
      if (this.imageUploaded) {
        const formData = new FormData()
        formData.append('user', JSON.stringify(this.user))
        formData.append('image', this.imageUploaded.raw)
        data = (await saveUserWithMedia(formData)).data
      } else {
        data = (await saveUser(this.user)).data
      }
      if (UserModule.officeId !== data.office_id) {
        return this.$router.push('/user/list')
      }
      if (!this.isEdit && !this.isProfile) {
        return this.redirectToEdit(data.id)
      }
      await successMsg('userDetail.successSave')
    } catch (err) {
      if (err.response && err.response.status === 409) {
        await errorMsg('api.emailAlreadyExists')
      } else {
        await errorMsg('api.serverError')
      }
    } finally {
      this.saveLoader = false
    }
  }
}
