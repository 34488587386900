import { UserTranslation } from '@/models/UserTranslation'
import { Office } from '@/models/Office'

/* eslint-disable camelcase */
export class User {
  id: string;
  translations: UserTranslation[];
  office: Office[] | null;
  active: boolean;
  code: string | null;
  main_email: string | null;
  username: string | null;
  owner: string | null;
  office_id: string | null;
  photo: string | null;
  phone: string | null;
  date_created: number | null;
  date_updated: number | null;
  meta: any;
  roles: any;

  constructor(data: any = {}) {
    this.id = data.id
    this.code = data.code || null
    this.translations = data.translations || []
    this.active = data.active || false
    this.office = data.office || null
    this.owner = data.owner || null
    this.office_id = data.office_id || null
    this.main_email = data.main_email || null
    this.username = data.username || null
    this.phone = data.phone || null
    this.photo = data.photo || null
    this.date_created = data.date_created || null
    this.date_updated = data.date_updated || null
    this.meta = data.meta || {}
    this.roles = data.roles || []
  }
}
